<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4" id="kt_footer">
      <div class="text-dark text-center">
        <span class="text-muted font-weight-bold mr-2">
          2020&nbsp;&copy;&nbsp;My Realty Cloud's Real Estate Management System
        </span>
      </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
