<template>
  <div>
    <div
        class="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
        :style="{ backgroundColor: `#00004d` }"
    >
      <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success"
          role="tablist"
      >
        <li class="nav-item">
          <a
              v-on:click="setActiveTab"
              data-tab="0"
              class="nav-link active"
              data-toggle="tab"
              href="#"
              role="tab"
          >
            Notifications ({{notificationsCount}})
          </a>
        </li>
        <li class="nav-item" v-if="notifications.length > 0">
          <a
              v-on:click="clearNotifications"
              data-tab="1"
              class="nav-link"
              data-toggle="tab"
          >
            Clear All
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8" v-if="notifications.length > 0">
        <perfect-scrollbar
            class="scroll pr-7 mr-n7"
            style="max-height: 40vh; position: relative;"
        >
          <template>
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-for="(item, i) in notifications" v-bind:key="i">
              <div class="d-flex flex-column font-weight-bold" v-if="item.type === 'listing'">
                <router-link
                    :to="{ path: '/listings', query: { rel: item.rel_id }}"
                    class="text-dark text-hover-primary mb-1 font-size-lg text-theme"
                >
                  New Listing assigned to you {{item.assigned_by_user ? " by " + item.assigned_by_user.name : ''}}
                </router-link>
                <span class="text-muted">
                  {{formatDateAndTime(item.created_at)}}
                </span>
              </div>

              <div class="d-flex flex-column font-weight-bold" v-if="item.type === 'lead'">
                <router-link
                    :to="{ path: '/leads', query: { rel: item.rel_id }}"
                    class="text-dark text-hover-primary mb-1 font-size-lg text-theme"
                >
                  New Lead assigned to you {{item.assigned_by_user ? " by " + item.assigned_by_user.name : ''}}
                </router-link>
                <span class="text-muted">
                  {{formatDateAndTime(item.created_at)}}
                </span>
              </div>
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab active class="p-8" v-else>
        <p>No new notification(s).</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import {CLEAR_NOTIFICATIONS, NOTIFICATIONS_LIST} from "@/core/services/store/notifications.module";

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex:0,
      notifications: [],
      notificationsCount: 0,
      apiTimeout: null
    };
  },
  mounted() {
    this.getNotififs()
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    getNotififs() {
      let that = this;
      this.apiTimeout = setInterval(function () {
        that.$store.dispatch(NOTIFICATIONS_LIST).then(data => {
          that.notifications = data.records;
          that.notificationsCount = data._metadata.numOfRecords;
          // if (data._metadata.numOfRecords === 0) that.$store.state.nCount = 0;
          that.$emit('setNotificationsCount', data._metadata.numOfRecords);
        });
      }, 10000);
    },
    clearNotifications(){
      clearInterval(this.apiTimeout)
      this.$store.dispatch(CLEAR_NOTIFICATIONS, {data: this.notifications}).then(() => {
        this.notifications = []
        this.notificationsCount = 0
        this.$emit('setNotificationsCount', 0);
        this.getNotififs()
      });
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>
