<template>
  <!-- begin:: Header -->
  <div>
    <div
        id="kt_top_header"
        ref="kt_top_header"
        class="header"
        v-bind:class="headerClasses"
    >
      <div
          class="container-fluid d-flex align-items-center justify-content-between"
      >
        <div
            class="header-menu-wrapper header-menu-wrapper-left"
        >
          <h6 class="mb-0">Welcome {{ this.$store.state.auth.user.name }}!</h6>

        </div>
        <div
            class="header-menu-wrapper header-menu-wrapper-left"
        >
          <v-form ref="qsMainForm" v-on:submit.prevent="hitQsMainForm">
            <v-text-field placeholder="Search (Minimum 4 Characters)"
                          style="width: 500px"
                          outlined rounded dense
                          v-model="$store.state.qsQuery"
                          class="mt-6"
            >
            </v-text-field>
          </v-form>
          <!--          <p class="mr-5 mb-0"><strong>Country:</strong> {{ this.$store.state.auth.user.country.title }}</p>-->
          <!--          <p class="mr-5 mb-0"><strong>City:</strong> {{ this.$store.state.auth.user.city.title }}</p>-->
          <!--          <p class="mr-3 mb-0"><strong>Currency:</strong> {{ this.$store.state.auth.user.country.currency_code }}</p>-->
          <!--          <p class="mr-3 mb-0"><strong>Measurement:</strong> {{ this.$store.state.auth.user.measurement_unit }}</p>-->
          <!--          <v-icon @click="shCCDialog" class="mr-1" small style="cursor: pointer; color:#00004d">fa fa-edit</v-icon>-->
          <!--          <v-icon @click="shQSDialog" small style="cursor: pointer; color:#00004d">fa fa-search</v-icon>-->
        </div>

        <KTTopbar></KTTopbar>
      </div>
    </div>

    <div
        id="kt_header"
        ref="kt_header"
        class="header"
        v-bind:class="headerClasses"
    >
      <div
          class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
            class="header-menu-wrapper header-menu-wrapper-left"
            ref="kt_header_menu_wrapper"
        >
          <div
              v-if="headerMenuEnabled"
              id="kt_header_menu"
              ref="kt_header_menu"
              class="header-menu header-menu-mobile"
              v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <KTMenu></KTMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import {mapGetters} from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import {bus} from "@/main";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_top_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
    );
  },
  methods: {
    hitQsMainForm() {
      if (this.$store.state.qsQuery.length >= 4) {
        bus.$emit('qsDialogOpen', true)
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
