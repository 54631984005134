<template>
  <v-snackbar v-model="show" top right :color="color">
    {{ message }}
    <v-btn text dark @click.native="show = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      message: '',
      color: ''
    }
  },
  computed: mapState(['snack']),
  created: function () {
    this.$store.watch(state => state.snackbar.snack, () => {
      const msg = this.$store.state.snackbar.snack.msg
      if (msg !== '') {
        this.show = true
        this.message = this.$store.state.snackbar.snack.msg
        this.color = this.$store.state.snackbar.snack.clr
      }
    })
  }
}
</script>