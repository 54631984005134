<template>
  <ul class="menu-nav">
    <v-form class="hidden-md-and-up" style="padding: 0 10px;" ref="qsMainForm" v-on:submit.prevent="hitQsMainForm">
      <v-text-field placeholder="Search (Min 4 Characters)"
                    style="width: 500px"
                    outlined rounded dense
                    v-model="$store.state.qsQuery"
                    class="mt-6"
      >
      </v-text-field>
    </v-form>
    <router-link
        v-if="hasPermission('dashboard.view')"
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
<!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="hasPermission('dashboard.view')"
        to="/market-insights"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
<!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">Market Insights</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/developments-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
<!--        <i class="menu-icon flaticon2-layers-2"></i>-->
        <span class="menu-text">Developments</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
              v-if="hasPermission('master_developments.view')"
              to="/developments-management/master-developments"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Master Developments</span>
              </a>
            </li>
          </router-link>
          <!--Sub Developments-->
          <router-link
              v-if="hasPermission('sub_developments.view')"
              to="/developments-management/sub-developments"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Sub Developments</span>
              </a>
            </li>
          </router-link>
          <!--Projects-->
          <router-link
              v-if="hasPermission('projects.view')"
              to="/developments-management/projects"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Projects</span>
              </a>
            </li>
          </router-link>
          <!--Unmatched Data-->
          <router-link
              v-if="$store.state.auth.user.type === 1"
              to="/developments-management/pm-unmatched-data"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Unmatched Data</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link
        v-if="hasPermission('listings.view')"
        to="/listings"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">Listings</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="hasPermission('leads.view')"
        to="/leads"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">Leads</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/contacts-management') }"
    >
      <router-link
          class="menu-link menu-toggle"
          to="/contacts-management/contacts"
      >
        <span class="menu-text">Contacts</span>
        <i class="menu-arrow"></i>
      </router-link>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!--Contact Roles-->
          <router-link
              v-if="hasPermission('contact_roles.view')"
              to="/contacts-management/contact-roles"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Contact Role/Group</span>
              </a>
            </li>
          </router-link>
          <!--Salutations-->
          <router-link
              v-if="hasPermission('salutations.view')"
              to="/contacts-management/salutations"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Salutations</span>
              </a>
            </li>
          </router-link>
          <!--Occupations-->
          <router-link
              v-if="hasPermission('occupations.view')"
              to="/contacts-management/occupations"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Occupation/Profession</span>
              </a>
            </li>
          </router-link>
          <!--Domains-->
          <router-link
              v-if="hasPermission('domains.view')"
              to="/contacts-management/domains"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Domain/Category/Sector</span>
              </a>
            </li>
          </router-link>
          <!--Labels-->
          <router-link
              v-if="hasPermission('labels.view')"
              to="/contacts-management/labels"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Labels</span>
              </a>
            </li>
          </router-link>
          <!--Contacts-->
          <router-link
              v-if="hasPermission('contacts.view')"
              to="/contacts-management/contacts"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Contacts</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/transactions-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Transactions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/investors-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Investors</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/property-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Properties</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">

        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/setup') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Setup</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav overflow-y-auto" style="max-height: 400px">
          <!--Countries-->
          <router-link
              v-if="hasPermission('countries.view')"
              to="/setup/countries"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Countries</span>
              </a>
            </li>
          </router-link>
          <!--Cities-->
          <router-link
              v-if="hasPermission('cities.view')"
              to="/setup/cities"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cities</span>
              </a>
            </li>
          </router-link>
          <!--Sources-->
          <router-link
              v-if="hasPermission('sources.view')"
              to="/setup/sources"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Sources</span>
              </a>
            </li>
          </router-link>
          <!--Completion-Status-->
          <router-link
              v-if="hasPermission('completion_status.view')"
              to="/setup/completion-status"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Completion Status</span>
              </a>
            </li>
          </router-link>
          <!--Comment Titles-->
          <router-link
              v-if="hasPermission('comment_titles.view')"
              to="/setup/comment-titles"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Comment Titles</span>
              </a>
            </li>
          </router-link>
          <!--Cards-->
          <router-link
              v-if="hasPermission('cards.view')"
              to="/setup/cards"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Debit/Credit Cards</span>
              </a>
            </li>
          </router-link>
          <!--Views-->
          <router-link
              v-if="hasPermission('views.view')"
              to="/setup/views"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Views</span>
              </a>
            </li>
          </router-link>
          <!--Brands-->
          <router-link
              v-if="hasPermission('brands.view')"
              to="/setup/brands"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Brands</span>
              </a>
            </li>
          </router-link>
          <!--Purposes-->
          <router-link
              v-if="hasPermission('purpose.view')"
              to="/setup/purposes"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Purpose</span>
              </a>
            </li>
          </router-link>
          <!--Publishing-Statuses-->
          <router-link
              v-if="hasPermission('publishing_status.view')"
              to="/setup/publishing-status"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Publishing Status</span>
              </a>
            </li>
          </router-link>
          <!--inquiry_Statuses-->
          <router-link
              v-if="hasPermission('inquiry_status.view')"
              to="/setup/inquiry-status"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inquiry Status</span>
              </a>
            </li>
          </router-link>
          <!--Property Types-->
          <router-link
              v-if="hasPermission('property_types.view')"
              to="/setup/property-types"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Property Types</span>
              </a>
            </li>
          </router-link>
          <!--Development Types-->
<!--          <router-link-->
<!--              v-if="hasPermission('development_types.view')"-->
<!--              to="/setup/development-types"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[-->
<!--                isActive && 'menu-item-active',-->
<!--                isExactActive && 'menu-item-active'-->
<!--              ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot">-->
<!--                  <span></span>-->
<!--                </i>-->
<!--                <span class="menu-text">Development/Project Title Types</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <!--Life cycles-->
          <router-link
              v-if="hasPermission('life_cycles.view')"
              to="/setup/life-cycles"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Life Cycles</span>
              </a>
            </li>
          </router-link>
          <!--Special classifications-->
          <router-link
              v-if="hasPermission('special_classifications.view')"
              to="/setup/special-classifications"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Special Classifications</span>
              </a>
            </li>
          </router-link>
          <!--Amenities-->
          <router-link
              v-if="hasPermission('amenities.view')"
              to="/setup/amenities"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Amenities/Features</span>
              </a>
            </li>
          </router-link>
          <!--Road Locations-->
          <router-link
              v-if="hasPermission('road_locations.view')"
              to="/setup/road-locations"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Road Locations</span>
              </a>
            </li>
          </router-link>
          <!--Location Remarks-->
          <router-link
              v-if="hasPermission('location_remarks.view')"
              to="/setup/location-remarks"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Location Remarks</span>
              </a>
            </li>
          </router-link>
          <!--Rent Frequencies-->
          <router-link
              v-if="hasPermission('frequencies.view')"
              to="/setup/rent-frequencies"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Rent Frequencies</span>
              </a>
            </li>
          </router-link>
          <!--Payment Plan Frequencies-->
          <router-link
              v-if="hasPermission('frequencies.view')"
              to="/setup/pp-frequencies"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Payment Plan Frequencies</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/portals-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Portal</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!--Menus-->
          <router-link
              v-if="hasPermission('menus.view')"
              to="/portals-management/menus"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Menus</span>
              </a>
            </li>
          </router-link>
          <!--Pages-->
          <router-link
              v-if="hasPermission('pages.view')"
              to="/portals-management/pages"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pages</span>
              </a>
            </li>
          </router-link>
          <!--Banners-->
          <router-link
              v-if="hasPermission('banners.view')"
              to="/portals-management/banners"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Banners</span>
              </a>
            </li>
          </router-link>
          <!--News-->
          <router-link
              v-if="hasPermission('news.view')"
              to="/portals-management/news"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">News</span>
              </a>
            </li>
          </router-link>
          <!--Blog-->
          <router-link
              v-if="hasPermission('blog.view')"
              to="/portals-management/blog"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Blog</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/users-panel') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Users</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!--Vendors-->
          <router-link
              v-if="hasPermission('vendors.view')"
              to="/users-panel/vendors"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Vendors</span>
              </a>
            </li>
          </router-link>
          <!--Teams-->
<!--          <router-link-->
<!--              v-if="hasPermission('teams.view')"-->
<!--              to="/users-panel/teams"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[-->
<!--                isActive && 'menu-item-active',-->
<!--                isExactActive && 'menu-item-active'-->
<!--              ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot">-->
<!--                  <span></span>-->
<!--                </i>-->
<!--                <span class="menu-text">Teams</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
          <!--Users-->
          <router-link
              v-if="hasPermission('users.view')"
              to="/users-panel/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Users</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/reports-panel') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Reporting</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <!--Reports-->
          <router-link
              v-if="hasPermission('reports.view')"
              to="/reports-panel/reports"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reports</span>
              </a>
            </li>
          </router-link>
          <router-link
              v-if="hasPermission('logs.view')"
              to="/reports-panel/logs"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Logs</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import {bus} from "@/main";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hitQsMainForm() {
      if (this.$store.state.qsQuery.length >= 4) {
        bus.$emit('qsDialogOpen', true)
      }
    },
  },
};
</script>
