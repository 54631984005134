<template>
  <v-dialog style="z-index: 99999" persistent v-model="$store.state.qsDialog" fullscreen>
    <v-card>
      <v-toolbar
          dark
          color="#00004d"
      >
        <v-toolbar-title class="pl-0">
          <span>Quick Search</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="$store.state.qsDialog = false; $store.state.qsQuery = ''"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field :counter="300"
                          outlined
                          dense
                          rounded
                          hide-details
                          maxlength="300"
                          v-model="$store.state.qsQuery"
                          :loading="isQueryLoaded"
                          @keyup="queryKeyUp"
                          placeholder="Search (Minimum 4 Characters)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <p v-for="(item, i) in queryData" :key="i" class="search-results-p mb-10">
              <span v-if="item.hasOwnProperty('commercial_title') && item.type === '0'">
                <a class="hidden-md-and-up" :href="'/developments-management/master-developments?development='+item.id">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                  <span class="font-weight-bold" v-html="item.commercial_title + ' - (Master Development)'"></span>
                </a>
                <a class="hidden-sm-and-down" :href="'/developments-management/master-developments?development='+item.id" target="_blank">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                  <span class="font-weight-bold" v-html="item.commercial_title + ' - (Master Development)'"></span>
                </a><br>
                <span v-if="item.description" v-html='item.description.substring(0,300)+"..."'></span>
              </span>
              <span v-if="item.hasOwnProperty('commercial_title') && item.type === '1'">
                <a class="hidden-md-and-up" :href="'/developments-management/sub-developments?development='+item.id">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development) ? item.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.parent_development && item.parent_development.parent_development" small
                          class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span v-html="item.parent_development ? item.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.parent_development" small class="ml-1 mr-1">fas
                    fa-angle-double-right
                  </v-icon>
                  <span class="font-weight-bold" v-html="item.commercial_title + ' - (Sub Development)'"></span>
                </a>
                <a class="hidden-sm-and-down" :href="'/developments-management/sub-developments?development='+item.id" target="_blank">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.parent_development && item.parent_development.parent_development) ? item.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.parent_development && item.parent_development.parent_development" small
                          class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span v-html="item.parent_development ? item.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.parent_development" small class="ml-1 mr-1">fas
                    fa-angle-double-right
                  </v-icon>
                  <span class="font-weight-bold" v-html="item.commercial_title + ' - (Sub Development)'"></span>
                </a>
                <br>
                <span v-if="item.description" v-html='item.description.substring(0,300)+"..."'></span>
              </span>
              <span v-if="item.hasOwnProperty('title')">
                <a class="hidden-md-and-up" :href="'/developments-management/projects?project='+item.id">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.development && item.development.parent_development" small
                          class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span v-html="item.development ? item.development.commercial_title : ''"></span>
                  <v-icon v-if="item.development" small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span class="font-weight-bold" v-html="item.title + ' - (Project)'"></span>
                </a>
                <a class="hidden-sm-and-down" :href="'/developments-management/projects?project='+item.id" target="_blank">
                  <span v-html="item.country ? item.country.title : ''"></span>
                  <v-icon v-if="item.country" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span v-html="item.city ? item.city.title : ''"></span>
                  <v-icon v-if="item.city" small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                  <v-icon
                      v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                      small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span
                      v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                  <v-icon v-if="item.development && item.development.parent_development" small
                          class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span v-html="item.development ? item.development.commercial_title : ''"></span>
                  <v-icon v-if="item.development" small class="ml-1 mr-1">fas fa-angle-double-right
                  </v-icon>

                  <span class="font-weight-bold" v-html="item.title + ' - (Project)'"></span>
                </a>
                <br>
                <span v-if="item.description" v-html='item.description.substring(0,300)+"..."'></span>
              </span>
              <span v-if="item.hasOwnProperty('full_name')">
                <a class="hidden-md-and-up" :href="'/contacts-management/contacts?contact='+item.id" target="_blank">
                  <span class="font-weight-bold" v-html="item.full_name + ' - (Contact)'"></span>
                </a>
                <a class="hidden-sm-and-down" :href="'/contacts-management/contacts?contact='+item.id" target="_blank">
                  <span class="font-weight-bold" v-html="item.full_name + ' - (Contact)'"></span>
                </a>
                <br>
                <span v-html="'Phone(s):- ' + item.contact_phone_titles"></span><br>
                <span v-html="'Email(s):- ' + item.contact_email_titles"></span><br>
                <span v-html="'Role(s):- ' + item.contact_role_titles"></span>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="totalRecords > 0">
          <v-col cols="12">
            <v-pagination
                :total-visible="15"
                color="#00004d"
                :disabled="isQueryLoaded"
                v-model="page"
                @input="getQueryData"
                :length="lpage">
            </v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";
import {QUICK_SEARCH} from "@/core/services/store/users.module";

export default {
  data() {
    return {
      isQueryLoaded: false,
      queryData: [],
      page: 1,
      lpage: 1,
      totalRecords: 0,
    };
  },
  mounted() {
    bus.$on('qsDialogOpen', data => {
      this.$store.state.qsDialog = data;
      this.queryData = []
      this.page = 1
      this.lpage = 1
      this.totalRecords = 0
      this.getQueryData()
    });
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    }),
    // pageCount() {
    //   // return this.totalRecords / this.pagination.rowsPerPage
    //   return this.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.itemsPerPage) : 0
    // }
  },
  methods: {
    queryKeyUp(e) {
      this.page = 1;
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 4) {
          that.isQueryLoaded = true;
          that.$store
              .dispatch(QUICK_SEARCH, {
                q: e.target.value,
                page: that.page
              })
              .then(data => {
                that.queryData = data.records.data
                that.isQueryLoaded = false
                that.page = data.records.current_page
                that.lpage = data.records.last_page
                that.totalRecords = data.records.total
              });
        }
      }, 900);
    },
    getQueryData() {
      this.isQueryLoaded = true
      this.$store
          .dispatch(QUICK_SEARCH, {
            q: this.$store.state.qsQuery,
            page: this.page
          })
          .then(data => {
            this.queryData = data.records.data
            this.isQueryLoaded = false
            this.page = data.records.current_page
            this.lpage = data.records.last_page
            this.totalRecords = data.records.total
          });
    },
  }
};
</script>