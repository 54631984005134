<template>
  <div class="container">
    <div class="row login-w3ls">
      <div class="col-md-12">
        <div class="card-content p-2 text-center">
          <h4>Select Your Country and City</h4>
          <br>
          <v-form
              ref="countryForm"
              v-model="validationRules.valid"
              lazy-validation
          >
            <v-autocomplete
                v-model="user.country_id"
                :items="countriesFilter"
                item-text="title"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="Country*"
                :rules="validationRules.countriesRules"
                @change="countrySelected"
            ></v-autocomplete>

            <v-autocomplete
                v-model="user.city_id"
                :items="citiesFilter"
                item-text="title"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="City*"
                :rules="validationRules.cityRules"
            >
            </v-autocomplete>
          </v-form>
          <v-col cols="12">
            <div v-if="errors!=undefined && errors.length >= 0"
                 role="alert"
                 v-bind:class="{ show: errors.length }"
                 class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
          </v-col>
          <hr>
          <button class="btn btn-primary btn-bold text-center mr-3" @click="onLogout">
            Back to Login
          </button>
          <button ref="saveCountryBtn" :disabled="!validationRules.valid" class="btn btn-theme btn-bold text-center"
                  @click="saveCountry(user)">
            Go To Profile
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DO_LOGOUT} from "@/core/services/store/auth.module";
// import Swal from "sweetalert2";
import {COUNTRIES_SIMPLE_LIST} from "@/core/services/store/countries.module";
import {mapState} from "vuex";
import {CLEAR_USER_ERRORS, UPDATE_USER_COUNTRY} from "@/core/services/store/users.module";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import {CITYS_SIMPLE_LIST} from "@/core/services/store/cities.module";

export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      countriesFilter: [],
      citiesFilter: [],
      validationRules: {
        valid: true,
        countriesRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Country';
            else return true;
          }
        ],
        cityRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select City';
            else return true;
          }
        ],
      },
    }
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(DO_LOGOUT)
    },
    getCountries() {
      this.$store.dispatch(COUNTRIES_SIMPLE_LIST).then((data) => {
        this.countriesFilter = data.records.countriesList;
      });
    },
    countrySelected(v){
      if (v){
        this.$store.dispatch(CITYS_SIMPLE_LIST, {country_id: v}).then((data) => {
          this.citiesFilter = data.records.citiesList;
        });
      }
    },
    saveCountry() {
      if (this.$refs.countryForm.validate()) {
        const submitButton = this.$refs["saveCountryBtn"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.disabled = true;

        this.$store.dispatch(CLEAR_USER_ERRORS);
        this.$store.dispatch(UPDATE_USER_COUNTRY, {data: {country: this.user.country_id, city: this.user.city_id}
        })
            .then((data) => {
              if (data._metadata.outcomeCode === 0) {
                this.$router.push({ path: '/users-panel/users?profile=1' })
              }
            })
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    })
  }
}
</script>