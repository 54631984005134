<template>
  <v-dialog persistent v-model="changeCountryDialog" max-width="500px">
    <v-card>
      <v-form
          ref="innerCountryForm"
          v-model="validationRules.valid"
          lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                  v-model="user.country_id"
                  :items="countriesFilter"
                  item-text="title"
                  item-value="id"
                  chips
                  outlined
                  dense
                  small-chips
                  label="Country*"
                  :rules="validationRules.countriesRules"
                  @change="countrySelected"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-autocomplete
                  v-model="user.city_id"
                  :items="citiesFilter"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  chips
                  small-chips
                  label="City*"
                  :rules="validationRules.cityRules"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  outlined
                  dense
                  chips
                  small-chips
                  :items="unitsFilterForUsers"
                  v-model="user.measurement_unit"
                  item-text="title"
                  item-value="value"
                  label="Measurement*"
                  :rules="validationRules.measurementRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeCcDialog">Cancel</v-btn>
          <v-btn :disabled="!validationRules.valid" @click="saveCountry" color="blue darken-1" text>
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import {COUNTRIES_SIMPLE_LIST} from "@/core/services/store/countries.module";
import {mapState} from "vuex";
import {CLEAR_USER_ERRORS, UPDATE_USER_COUNTRY} from "@/core/services/store/users.module";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import {CITYS_SIMPLE_LIST} from "@/core/services/store/cities.module";
import {bus} from "@/main";

export default {
  data() {
    return {
      changeCountryDialog: false,
      user: this.$store.state.auth.user,
      countriesFilter: [],
      citiesFilter: [],
      validationRules: {
        valid: true,
        countriesRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Country';
            else return true;
          }
        ],
        cityRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select City';
            else return true;
          }
        ],
        measurementRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Measurement';
            else return true;
          }
        ],
      },
    };
  },
  mounted() {
    this.getCountries();
    bus.$on('cCDialogOpen', data => {
      this.changeCountryDialog = data;
    });
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    }),
  },
  methods: {
    getCountries() {
      this.$store.dispatch(COUNTRIES_SIMPLE_LIST).then((data) => {
        this.countriesFilter = data.records.countriesList;
        if (this.$store.state.auth.user.country_id) {
          this.countrySelected(this.$store.state.auth.user.country_id, false);
        }
      });
    },
    countrySelected(v, eC = true) {
      if (v) {
        if (eC) this.user.city_id = null;
        this.$store.dispatch(CITYS_SIMPLE_LIST, {country_id: v}).then((data) => {
          this.citiesFilter = data.records.citiesList;
        });
      }
    },
    saveCountry() {
      if (this.$refs.innerCountryForm.validate()) {
        this.$store.dispatch(CLEAR_USER_ERRORS);
        this.$store.dispatch(UPDATE_USER_COUNTRY, {
          data: {country: this.user.country_id, city: this.user.city_id, measurement_unit: this.user.measurement_unit}
        })
            .then(() => {
              window.location.reload();
              this.$store.dispatch(VERIFY_AUTH)
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
              this.changeCountryDialog = !this.changeCountryDialog
            })
      }
    },
    closeCcDialog(){
      this.changeCountryDialog = false
    }
  }
};
</script>